import React, { memo } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { animated, useSprings, config } from "react-spring"
import useMeasure from "react-use-measure"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import {
  MenuSubheading,
  Copy,
  Subheading,
  MenuThirdHeading,
} from "../components/typography"

// eslint-disable-next-line react/display-name
const AnimatedItem = memo(({ children, opacity, fullWidth, doubleBottom }) => {
  const [bind, { height: viewHeight }] = useMeasure()
  return (
    <animated.div
      style={{
        opacity,
        overflow: "hidden",
        gridColumn: `${fullWidth ? "1 / -1" : "span 1"}`,
        maxWidth: `${fullWidth ? "50ch" : "auto"}`,
        height: viewHeight
          ? opacity.interpolate((o) => `${o * viewHeight}px`)
          : "auto",
        marginBottom:
          viewHeight && doubleBottom
            ? opacity.interpolate((o) => `${o * 30}px`)
            : "0px",
      }}
    >
      <div style={{ overflow: "hidden" }} ref={bind}>
        {children}
      </div>
    </animated.div>
  )
})

AnimatedItem.propTypes = {
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  opacity: PropTypes.object,
  fullWidth: PropTypes.bool,
  doubleBottom: PropTypes.bool,
}

const ItemContainer = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 0;
  margin-bottom: 60px;

  & p {
    max-width: 45ch;
  }

  @media screen and (min-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0;
    margin-bottom: 60px;
  }

  @media screen and (min-width: 1200px) {
    width: 1110px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;
  }
`

const MenuHeading = styled(ItemContainer)`
  margin-bottom: 0;
  & > p {
    margin-bottom: 60px;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 0;

    & > p {
      margin-bottom: 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;

    & > p {
      margin-bottom: 60px;
    }
  }
`

const CollapsibleMenuSection = styled.div`
  display: grid;
  margin-left: 25px;
  margin-right: 25px;

  & > button > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid hsl(0, 0%, 20%);

    & > svg {
      width: 44px;
      height: 44px;
    }
  }

  & h2 {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media screen and (min-width: 1200px) {
    width: 1110px;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      margin-top: 120px;
    }

    &:last-of-type {
      margin-bottom: 120px;
    }
  }
`

const CollapsibleItemsContainer = styled(animated.div)`
  display: grid;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    /* row-gap: 15px; */

    & > :nth-child(n) {
      grid-column: span 1;
    }
  }

  @media screen and (min-width: 992px) {
    column-gap: 60px;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Footnote = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: min-content;
  column-gap: 20px;
  align-items: center;
  margin-top: 30px;

  & > * {
    grid-row: 1;
  }

  & > hr {
    border: 0.5px dashed hsl(0, 0%, 20%);
    height: 100%;
  }
`

const Menu = ({
  location,
  data: {
    contentfulMenu: {
      seoTitle,
      seoDescription,
      seoKeywords,
      image,
      menuName,
      copy,
      menuContent,
    },
  },
}) => {
  const [collapsibleMenuSprings, setCollapsibleMenuSprings] = useSprings(menuContent.length,
    () => ({
      from: {
        isCollapsed: 1,
        opacity: 0,
        marginBottom: "0px",
        rowGap: "0px",
      },
      to: {
        isCollapsed: 0,
        opacity: 1,
        marginBottom: "60px",
        rowGap: "0px",
      },
      config: config.default,
    })
  )

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        meta={[{ name: "keywords", content: seoKeywords.join(", ") }]}
      />
      <Header
        image={image}
        title={menuName}
        text={copy ? copy.copy : null}
        backLink={
          typeof window !== 'undefined' 
            ? location?.state?.referrer || "/menu/" 
            : "/menu/"
        }
      />
      {menuContent.map((menuItem, index) =>
        menuItem.__typename === "ContentfulMenuHeading" ? (
          <MenuHeading key={index}>
            {menuItem.menuSectionHeading ? (
              <MenuSubheading>{menuItem.menuSectionHeading}</MenuSubheading>
            ) : null}
            {menuItem.menuSubsectionHeading ? (
              <Subheading>{menuItem.menuSubsectionHeading}</Subheading>
            ) : null}
            {menuItem.menuDescription
              ? menuItem.menuDescription.menuDescription
                  .split("\n")
                  .map((paragraph, paragraphIndex) => (
                    <Copy key={paragraphIndex}>{paragraph}</Copy>
                  ))
              : null}
          </MenuHeading>
        ) : menuItem.__typename === "ContentfulMenuItem" ? (
          <ItemContainer key={index}>
            {menuItem.displayName ? (
              <MenuThirdHeading>{menuItem.displayName}</MenuThirdHeading>
            ) : null}
            {menuItem.description
              ? menuItem.description.description
                  .split("\n")
                  .map((paragraph, paragraphIndex) => (
                    <Copy key={paragraphIndex}>{paragraph}</Copy>
                  ))
              : null}
          </ItemContainer>
        ) : menuItem.__typename === "ContentfulMenuFootnote" ? (
          <ItemContainer key={index}>
            <Footnote>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 44 44"
                width="44"
                height="44"
              >
                <path
                  fill="white"
                  stroke="hsl(0, 0%, 20%)"
                  strokeMiterlimit="10"
                  d="M25.7,24.6l10.7,14.2l-4,3L22,27.1L11.7,41.8l-4.1-3l10.7-14.2L1,20.3l1.4-4.9l17.4,5L19.5,2.2h5.1l-0.3,18.2
                    l17.4-5l1.4,4.9L25.7,24.6z"
                />
              </svg>
              <hr />
              <div>
                {menuItem.footnote
                  ? menuItem.footnote.footnote
                      .split("\n")
                      .map((paragraph, paragraphIndex) => (
                        <Copy key={paragraphIndex}>{paragraph}</Copy>
                      ))
                  : null}
              </div>
            </Footnote>
          </ItemContainer>
        ) : menuItem.__typename === "ContentfulCollapsibleMenuSection" ? (
          <CollapsibleMenuSection key={index}>
            <button
              onClick={() => {
                setCollapsibleMenuSprings((i) => {
                  if (index !== i) return
                  return collapsibleMenuSprings[index].opacity.value
                    ? { reverse: true }
                    : { reverse: false }
                })
              }}
            >
              <div>
                {menuItem.menuSectionHeading ? (
                  <MenuSubheading>{menuItem.menuSectionHeading}</MenuSubheading>
                ) : null}

                <animated.svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 44 44"
                  width="44"
                  height="44"
                  style={{
                    transform: collapsibleMenuSprings[
                      index
                    ].opacity.interpolate((o) => `rotate(${o * 180}deg)`),
                  }}
                >
                  <path
                    id="Rectangle_114"
                    fill="none"
                    d="M9,0h26c5,0,9,4,9,9v26c0,5-4,9-9,9H9c-5,0-9-4-9-9V9C0,4,4,0,9,0z"
                  />
                  <path
                    id="path"
                    fill="none"
                    stroke="#3C3C3C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16,21l6,6l6-6"
                  />
                </animated.svg>
              </div>
            </button>
            <CollapsibleItemsContainer style={collapsibleMenuSprings[index]}>
              {menuItem.menuSectionDescription
                ? menuItem.menuSectionDescription.menuSectionDescription
                    .split("\n")
                    .map((paragraph, paragraphIndex) => (
                      <AnimatedItem
                        opacity={collapsibleMenuSprings[index].opacity}
                        key={index}
                        fullWidth={true}
                      >
                        <Copy key={paragraphIndex}>{paragraph}</Copy>
                      </AnimatedItem>
                    ))
                : null}
              {menuItem.contents
                ? menuItem.contents.map((contentItem, menuItemIndex) =>
                    contentItem.__typename === "ContentfulMenuHeading" ? (
                      <AnimatedItem
                        isHidden={
                          collapsibleMenuSprings[index].isCollapsed.value
                            ? true
                            : false
                        }
                        opacity={collapsibleMenuSprings[index].opacity}
                        key={menuItemIndex}
                        fullWidth={true}
                      >
                        {contentItem.menuSectionHeading ? (
                          <MenuSubheading
                            style={{
                              marginBottom: "-5px",
                            }}
                          >
                            {contentItem.menuSectionHeading}
                          </MenuSubheading>
                        ) : null}
                        {contentItem.menuSubsectionHeading ? (
                          <Subheading
                            style={{
                              marginBottom: "-5px",
                            }}
                          >
                            {contentItem.menuSubsectionHeading}
                          </Subheading>
                        ) : null}
                        {contentItem.menuDescription
                          ? contentItem.menuDescription.menuDescription
                              .split("\n")
                              .map((paragraph, paragraphIndex) => (
                                <Copy key={paragraphIndex}>{paragraph}</Copy>
                              ))
                          : null}
                      </AnimatedItem>
                    ) : contentItem.__typename === "ContentfulMenuItem" ? (
                      <AnimatedItem
                        isHidden={
                          collapsibleMenuSprings[index].isCollapsed.value
                            ? true
                            : false
                        }
                        opacity={collapsibleMenuSprings[index].opacity}
                        key={menuItemIndex}
                        fullWidth={false}
                        doubleBottom={true}
                      >
                        {contentItem.displayName ? (
                          <MenuThirdHeading>
                            {contentItem.displayName}
                          </MenuThirdHeading>
                        ) : null}
                        {contentItem.description
                          ? contentItem.description.description
                              .split("\n")
                              .map((paragraph, paragraphIndex) => (
                                <Copy key={paragraphIndex}>{paragraph}</Copy>
                              ))
                          : null}
                      </AnimatedItem>
                    ) : contentItem.__typename === "ContentfulMenuFootnote" ? (
                      <AnimatedItem
                        isHidden={
                          collapsibleMenuSprings[index].isCollapsed.value
                            ? true
                            : false
                        }
                        opacity={collapsibleMenuSprings[index].opacity}
                        key={menuItemIndex}
                        fullWidth={true}
                      >
                        <Footnote>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 44 44"
                            width="44"
                            height="44"
                          >
                            <path
                              fill="white"
                              stroke="hsl(0, 0%, 20%)"
                              strokeMiterlimit="10"
                              d="M25.7,24.6l10.7,14.2l-4,3L22,27.1L11.7,41.8l-4.1-3l10.7-14.2L1,20.3l1.4-4.9l17.4,5L19.5,2.2h5.1l-0.3,18.2
		                            l17.4-5l1.4,4.9L25.7,24.6z"
                            />
                          </svg>
                          <hr />
                          {contentItem.footnote
                            ? contentItem.footnote.footnote
                                .split("\n")
                                .map((paragraph, paragraphIndex) => (
                                  <Copy key={paragraphIndex}>{paragraph}</Copy>
                                ))
                            : null}
                        </Footnote>
                      </AnimatedItem>
                    ) : null
                  )
                : null}
            </CollapsibleItemsContainer>
          </CollapsibleMenuSection>
        ) : null
      )}
    </Layout>
  )
}

export default Menu

Menu.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      referrer: PropTypes.string,
    }),
  }),
  data: PropTypes.shape({
    contentfulMenu: PropTypes.shape({
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.string,
      seoKeywords: PropTypes.array,
      image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          details: PropTypes.shape({
            image: PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
            }),
          }),
        }),
        fluid: PropTypes.object,
      }),
      menuName: PropTypes.string,
      copy: PropTypes.shape({
        copy: PropTypes.string,
      }),
      menuContent: PropTypes.arrayOf(
        PropTypes.oneOfType([
          // ContentfulMenuSectionHeading
          PropTypes.shape({
            menuSectionHeading: PropTypes.string,
            menuSectionDescription: PropTypes.shape({
              menuSectionDescription: PropTypes.string,
            }),
          }),
          // ContentfulMenuItem
          PropTypes.shape({
            displayName: PropTypes.string,
            description: PropTypes.shape({
              description: PropTypes.string,
            }),
          }),
          // ContentfulCollapsableMenuSection
          PropTypes.shape({
            menuSectionHeading: PropTypes.string,
            menuSectionDescription: PropTypes.shape({
              menuSectionDescription: PropTypes.string,
            }),
            contents: PropTypes.arrayOf(
              // ContentfulMenuSectionHeading
              PropTypes.shape({
                menuSectionHeading: PropTypes.string,
                menuSectionDescription: PropTypes.shape({
                  menuSectionDescription: PropTypes.string,
                }),
              }),
              // ContentfulMenuSubsectionHeading
              PropTypes.shape({
                menuSubsectionHeading: PropTypes.string,
                menuSubsectionDescription: PropTypes.shape({
                  menuSubsectionDescription: PropTypes.string,
                }),
              }),
              // ContentfulMenuItem
              PropTypes.shape({
                displayName: PropTypes.string,
                description: PropTypes.shape({
                  description: PropTypes.string,
                }),
              }),
              // ContentfulMenuFootnote
              PropTypes.shape({
                footnote: PropTypes.shape({
                  footnote: PropTypes.string,
                }),
              })
            ),
          }),
        ])
      ),
    }),
  }),
}

export const query = graphql`
  query MenuQuery($slug: String!) {
    contentfulMenu(slug: { eq: $slug }) {
      slug
      seoTitle
      seoDescription
      seoKeywords
      image {
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      menuName
      copy {
        copy
      }
      menuContent {
        ... on ContentfulMenuHeading {
          menuSectionHeading
          menuSubsectionHeading
          menuDescription {
            menuDescription
          }
        }
        ... on ContentfulMenuFootnote {
          footnote {
            footnote
          }
        }
        ... on ContentfulCollapsibleMenuSection {
          menuSectionHeading
          menuSectionDescription {
            menuSectionDescription
          }
          contents {
            ... on ContentfulMenuHeading {
              menuSectionHeading
              menuSubsectionHeading
              menuDescription {
                menuDescription
              }
            }
            ... on ContentfulMenuItem {
              displayName
              description {
                description
              }
            }
            ... on ContentfulMenuFootnote {
              footnote {
                footnote
              }
            }
          }
        }
      }
    }
  }
`
